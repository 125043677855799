import React, { FC, ReactNode, createContext, useReducer } from "react";

import { Campaign, CampaignTile, Partner, Coupon, Newspaper, User } from "../../types";
import { ModalProps } from "../../components/organisms/Modal";

interface ActionProps {
  type: string;
  payload?: any;
}

interface StateProps {
  newspaper: Newspaper;
  user: User;
  campaigns: CampaignTile[];
  campaign: Campaign;
  coupons: Coupon[];
  partner: Partner;
  modal: ModalProps;
}

interface ActionsProps {
  storeNewspaper: (arg: Newspaper) => void;
  storeUser: (arg: User) => void;
  resetUser: () => void;
  storeCampaigns: (arg: CampaignTile[]) => void;
  storeCampaign: (arg: Campaign) => void;
  resetCampaign: () => void;
  storeCoupons: (arg: Coupon[]) => void;
  resetCoupons: () => void;
  storePartner: (arg: Partner) => void;
  resetPartner: () => void;
  storeModal: (arg: ModalProps) => void;
  resetModal: () => void;
}

interface AppProviderProps {
  children: ReactNode;
}

const initialState = {
  newspaper: null,
  user: null,
  campaigns: null,
  campaign: null,
  coupons: null,
  partner: null,
  modal: null
};

const reducer = (state: StateProps, action: ActionProps) => {
  switch (action.type) {
    case "store_newspaper":
      return {
        ...state,
        newspaper: action.payload
      };

    case "store_user":
      return {
        ...state,
        user: action.payload
      };

    case "reset_user":
      return {
        ...state,
        user: null
      };

    case "store_campaigns":
      return {
        ...state,
        campaigns: action.payload
      };

    case "store_campaign":
      return {
        ...state,
        campaign: action.payload
      };

    case "reset_campaign":
      return {
        ...state,
        campaign: null
      };

    case "store_coupons":
      return {
        ...state,
        coupons: action.payload
      };

    case "reset_coupons":
      return {
        ...state,
        coupons: null
      };

    case "store_partner":
      return {
        ...state,
        partner: action.payload
      };

    case "reset_partner":
      return {
        ...state,
        partner: null
      };

    case "store_modal":
      return {
        ...state,
        modal: action.payload
      };

    case "reset_modal":
      return {
        ...state,
        modal: null
      };

    default:
      return state;
  }
};

export const AppContext = createContext({} as StateProps & ActionsProps);

export const AppProvider: FC<AppProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    ...state,
    storeNewspaper: (arg) =>
      dispatch({ type: "store_newspaper", payload: arg }),
    storeUser: (arg) => dispatch({ type: "store_user", payload: arg }),
    resetUser: () => dispatch({ type: "reset_user" }),
    storeCampaigns: (arg) => dispatch({ type: "store_campaigns", payload: arg }),
    storeCampaign: (arg) => dispatch({ type: "store_campaign", payload: arg }),
    resetCampaign: () => dispatch({ type: "reset_campaign" }),
    storeCoupons: (arg) => dispatch({ type: "store_coupons", payload: arg }),
    resetCoupons: () => dispatch({ type: "reset_coupons" }),
    storePartner: (arg) => dispatch({ type: "store_partner", payload: arg }),
    resetPartner: () => dispatch({ type: "reset_partner" }),
    storeModal: (arg) => dispatch({ type: "store_modal", payload: arg }),
    resetModal: () => dispatch({ type: "reset_modal" }),
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

import React, { useState, Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { AppProvider } from "./components/_functional/AppProvider";
import KeyboardNavigationHandler from "./components/_functional/KeyboardNavigationHandler";
import GlobalStyle from "./components/_globalStyles/globalStyles";
import Loader from "./components/atoms/Loader";

const CouponPage = lazy(() => import("./pages/Coupon"));
const CampaignPage = lazy(() => import("./pages/Campaign"));
const CampaignsPage = lazy(() => import("./pages/Campaigns"));
const Session = lazy(() => import("./pages/Session"));

const App = () => {
  const [isFreshLoad, setIsFreshLoad] = useState(true);

  return (
    <>
      <GlobalStyle />
      <KeyboardNavigationHandler />
      <AppProvider>
        <Router>
          <Suspense fallback={<Loader active />}>
            <Routes>
              <Route
                path="/:newspaperSlug"
                element={<CampaignsPage setIsFreshLoad={setIsFreshLoad} />}
              />
              <Route
                path="/:newspaperSlug/campaigns/:campaignId"
                element={<CampaignPage setIsFreshLoad={setIsFreshLoad} />}
              />
              <Route
                path="/:newspaperSlug/session"
                element={<Session setIsFreshLoad={setIsFreshLoad} />}
              />
              <Route
                path="/collect/:partnerCode"
                element={<CouponPage isFreshLoad={isFreshLoad} />}
              />

              <Route
                path="*"
                element={
                  <main className="dsl-temp">
                    Sorry, there's nothing here yet :(
                  </main>
                }
              />
            </Routes>
          </Suspense>
        </Router>
      </AppProvider>
    </>
  );
};

export default App;

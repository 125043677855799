import { createGlobalStyle } from "styled-components";

import { pxToRem, pxToEm } from '../../util/helpers';
import constants from "./constants";

const GlobalStyle = createGlobalStyle`
  html {
    font-size: ${constants.ROOT_FONT_SIZE}px;
  }

  body {
    font-family: ${constants.ROOT_FONT_FAMILY};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    line-height: 1.5;
    color: ${constants.TEXT_COLOR};
    margin: 0;

    *::-webkit-scrollbar {
    display: none;
    }
  }

  input,
  button,
  select,
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    margin: 0.75em 0;
  }

  h1 {
    font-size: ${pxToRem(28)};
    line-height: ${pxToEm(32, 28)};
  }

  h2 {
    font-size: ${pxToRem(24)};
    line-height: ${pxToEm(28, 24)};
  }

  h3 {
    font-size: ${pxToRem(18)};
    line-height: ${pxToEm(24, 18)};
  }

  h4,
  h5,
  h6 {
    font-size: ${pxToRem(16)};
    line-height: ${pxToEm(18, 16)};
  }

  p {
    font-size: ${pxToRem(18)};
    margin: 1rem 0;
    line-height: ${pxToEm(24, 18)};

    a,
    a:link,
    a:visited {
      color: ${constants.LINK_COLOR};
      text-decoration: none;
    }

    a:hover {
      color: ${constants.LINK_HOVER_COLOR};
    }

    a:active {
      color: ${constants.LINK_ACTIVE_COLOR};
    }
  }

  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
  }

  // Use it to hide element visually but keep it for screen readers (e.g. custom inputs, checkboxes etc).
  .dsl-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .dsl-temp {
    margin: 10vh 16px;
    text-align: center;
  }

  .dsl-keyboard-navigation-on a:focus,
  .dsl-keyboard-navigation-on button:focus,
  .dsl-keyboard-navigation-on img:focus {
    outline: 3px dotted ${constants.FOCUS_OUTLINE_COLOR};
    outline-offset: 2px;
  }
`;

export default GlobalStyle;

const ROOT_FONT_FAMILY = "'Roboto', Arial, sans-serif";
const ROOT_FONT_SIZE = 16;
const GRID_UNIT = 8;

/**
  Colors naming ia based on similar approach as in sl-design-system.
  AA, AAA stand for level of WCAG compliance (if used in combination with white color).
  If there is no level mentioned, the colour is not complaint with any.
  "Large" text is the text starting from ~18px.
  All the texts should have colors with at least AA compliance.
**/

const COLOR_WHITE = "#FFFFFF";
const COLOR_BLACK = "#000000"; // AAA
const COLOR_BLUE_100 = "#B2D5F6";
const COLOR_BLUE_325 = "#006CD6"; // AA
const COLOR_BLUE_350 = "#0073E5"; // AA
const COLOR_BLUE_400 = "#1450C7"; // AAA
const COLOR_BLUR_450 = "#2272AC"; // AA
const COLOR_RED_200 = "#E52525"; // AA
const COLOR_GREEN_300 = "#00A300";
const COLOR_GRAY_100 = "#F5F5F5";
const COLOR_GRAY_190 = "#E6E6E6";
const COLOR_GRAY_300 = "#B3B3B3";
const COLOR_GRAY_340 = "#949494"; // AA, only for large text.
const COLOR_GRAY_375 = "#737373"; // AA
const COLOR_GRAY_450 = "#4D4D4D"; // AAA
const COLOR_GRAY_500 = "#333333"; // AAA
const COLOR_GRAY_900 = "#0f0f0f"; // AAA

const BORDER_COLOR = "rgba(0, 0, 0, 0.08)";
const FOCUS_OUTLINE_COLOR = COLOR_BLUE_350; // "rgba(0, 0, 0, 0.8)" is used in other products;
const INPUT_BORDER_COLOR = COLOR_GRAY_340;
const INPUT_FOCUS_BORDER_COLOR = COLOR_GRAY_500;
const TEXT_COLOR = COLOR_GRAY_900;
const BUTTON_PRIMARY_COLOR = COLOR_BLUE_350;
const BUTTON_PRIMARY_HOVER_COLOR = COLOR_BLUE_325;
const BUTTON_PRIMARY_ACTIVE_COLOR = COLOR_BLUE_400;
const BUTTON_PRIMARY_DISABLED_COLOR = COLOR_BLUE_100;
const BUTTON_PRIMARY_TEXT_COLOR = COLOR_WHITE;
const BUTTON_SECONDARY_COLOR = COLOR_WHITE;
const BUTTON_SECONDARY_HOVER_COLOR = COLOR_GRAY_100;
const BUTTON_SECONDARY_ACTIVE_COLOR = COLOR_GRAY_190;
const BUTTON_SECONDARY_TEXT_COLOR = COLOR_GRAY_500;
const BUTTON_ICON_TEXT_COLOR = COLOR_GRAY_500;
const BUTTON_MORE_COLOR = COLOR_BLUR_450;
const ICON_BADGE_COLOR = COLOR_GRAY_100;
const ICON_BADGE_TEXT_COLOR = COLOR_GRAY_375;
const INVERTED_TILE_COLOR = COLOR_GRAY_100;
const TEXT_LIGHTER_COLOR = COLOR_GRAY_450;
const LINK_COLOR = COLOR_BLUE_350;
const LINK_HOVER_COLOR = COLOR_BLUE_400;
const LINK_ACTIVE_COLOR = COLOR_BLUE_400;
const NO_MEDIA_ICON_COLOR = COLOR_GRAY_300;
const PLACE_ICON_COLOR = COLOR_GRAY_375;
const ERROR_COLOR = COLOR_RED_200;
const INPUT_LABEL_COLOR = COLOR_GRAY_450;
const INPUT_PLACEHOLDER_COLOR = COLOR_GRAY_375;
const INPUT_DISABLED_COLOR = COLOR_GRAY_375;
const CHECKBOX_OFF_BORDER_COLOR = COLOR_GRAY_375;
const CHECKBOX_ON_COLOR = COLOR_BLUE_350;
const CHECKBOX_ON_TEXT_COLOR = COLOR_WHITE;
const LOADER_COLOR = COLOR_GRAY_190;

const constants = {
  ROOT_FONT_FAMILY,
  ROOT_FONT_SIZE,
  GRID_UNIT,
  BORDER_COLOR,
  FOCUS_OUTLINE_COLOR,
  INPUT_BORDER_COLOR,
  TEXT_COLOR,
  TEXT_LIGHTER_COLOR,
  LINK_COLOR,
  LINK_HOVER_COLOR,
  LINK_ACTIVE_COLOR,
  ERROR_COLOR,
  INPUT_PLACEHOLDER_COLOR,
  INPUT_LABEL_COLOR,
  INPUT_FOCUS_BORDER_COLOR,
  INPUT_DISABLED_COLOR,
  BUTTON_PRIMARY_COLOR,
  BUTTON_PRIMARY_HOVER_COLOR,
  BUTTON_PRIMARY_ACTIVE_COLOR,
  BUTTON_PRIMARY_DISABLED_COLOR,
  BUTTON_PRIMARY_TEXT_COLOR,
  BUTTON_SECONDARY_COLOR,
  BUTTON_SECONDARY_HOVER_COLOR,
  BUTTON_SECONDARY_ACTIVE_COLOR,
  BUTTON_SECONDARY_TEXT_COLOR,
  BUTTON_ICON_TEXT_COLOR,
  BUTTON_MORE_COLOR,
  ICON_BADGE_COLOR,
  ICON_BADGE_TEXT_COLOR,
  NO_MEDIA_ICON_COLOR,
  PLACE_ICON_COLOR,
  INVERTED_TILE_COLOR,
  CHECKBOX_OFF_BORDER_COLOR,
  CHECKBOX_ON_COLOR,
  CHECKBOX_ON_TEXT_COLOR,
  LOADER_COLOR,
  COLOR_WHITE,
  COLOR_BLACK,
  COLOR_BLUE_100,
  COLOR_BLUE_325,
  COLOR_BLUE_350,
  COLOR_BLUE_400,
  COLOR_RED_200,
  COLOR_GREEN_300,
  COLOR_GRAY_100,
  COLOR_GRAY_190,
  COLOR_GRAY_300,
  COLOR_GRAY_340,
  COLOR_GRAY_375,
  COLOR_GRAY_450,
  COLOR_GRAY_500
};

export default constants;

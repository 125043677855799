import React, { useEffect, FC } from "react";

const KeyboardNavigationHandler: FC = () => {
  const keyboardNavigationOn = (e) => {
    // If tab is pressed.
    if (
      e.keyCode === 9 &&
      !document.body.classList.contains("dsl-keyboard-navigation-on")
    ) {
      document.body.classList.add("dsl-keyboard-navigation-on");

      document.body.removeEventListener("keydown", keyboardNavigationOn);
      document.body.addEventListener("mousedown", keyboardNavigationOff);
      document.body.addEventListener("touchmove", keyboardNavigationOff);
    }
  };

  const keyboardNavigationOff = (e) => {
    if (
      (e.type === "mousedown" || e.type === "touchmove") &&
      document.body.classList.contains("dsl-keyboard-navigation-on")
    ) {
      document.body.classList.remove("dsl-keyboard-navigation-on");

      document.body.removeEventListener("mousedown", keyboardNavigationOff);
      document.body.removeEventListener("touchmove", keyboardNavigationOff);
      document.body.addEventListener("keydown", keyboardNavigationOn);
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", keyboardNavigationOn);

    return () => {
      document.body.removeEventListener("keydown", keyboardNavigationOn);
      document.body.removeEventListener("mousedown", keyboardNavigationOff);
      document.body.removeEventListener("touchmove", keyboardNavigationOff);
    };
  }, []);

  return <></>;
};

export default KeyboardNavigationHandler;

import React, { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { LoaderWrap, LoaderBounce } from "./styled";

interface LoaderProps {
  children?: ReactNode;
  active: boolean;
}

const Loader: FC<LoaderProps> = ({ children, active }) => {
  const { t } = useTranslation();

  return active ? (
    <LoaderWrap>
      <LoaderBounce />
      <LoaderBounce />
      <LoaderBounce />
      <span role="status" className="dsl-visually-hidden">
        {t("accessibility.data_loading")}
      </span>
    </LoaderWrap>
  ) : (
    <>{children || null}</>
  );
};

export default Loader;

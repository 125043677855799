import constants from "../components/_globalStyles/constants";

export function pxToEm(px: number, fontSize: number): string {
  return `${px / fontSize}em`;
}

export function pxToRem(px: number): string {
  return `${px / constants.ROOT_FONT_SIZE}rem`;
}

export function truncateText(text: string, maxLength: number): string {
  if (text.length <= maxLength) return text;

  return text.slice(0, maxLength) + "...";
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function valueExists(value: any): boolean {
  return (typeof value === "number" && value === 0) || Boolean(value);
}

export function genDocumentLang(locale: string): string {
  switch (locale) {
    case "sv_SE":
      return "sv";
    default:
      return locale;
  }
}

export function getDateLocale(locale: string): string {
  switch (locale) {
    case "no":
      return "no-NO";
    case "sv_SE":
      return "sv-SE";
    case "fi":
      return "fi-FI";
    case "da":
      return "da-DK";
    case "pl":
      return "pl-PL";
    default:
      return "en";
  }
}

export function setDocumentLang(locale: string): void {
  document.documentElement.lang = genDocumentLang(locale);
}

export function setPageTitle(title: string): void {
  document.title = title;
}

export function setPageDescription(desc: string): void {
  const el = document.querySelector("meta[name='description']");
  el.setAttribute("content", desc);
}

export function setFavicon(favicon: {
  favicon32: string;
  favicon192: string;
}): void {
  const favicon32 = document.getElementById(
    "dsl_favicon_32"
  ) as HTMLLinkElement | null;
  const favicon192 = document.getElementById(
    "dsl_favicon_192"
  ) as HTMLLinkElement | null;

  favicon32.href = favicon.favicon32;
  favicon192.href = favicon.favicon192;
}
